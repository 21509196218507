import {AppNavbar} from '@checkit/react-components';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import {connect} from 'react-redux';
import {LogoutFunction} from '../../auth/state';
import {State} from '../../state';
import useStyles from './styles';

interface Props {
  logoutFunction: LogoutFunction | null;
  handleDrawerToggle: () => void;
}

const TopNavbar: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <AppNavbar
        logout={props.logoutFunction || undefined}
        position="fixed"
        className={classes.appBar}
    >
      <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={props.handleDrawerToggle}
          className={classes.menuButton}
      >
        <MenuIcon/>
      </IconButton>
    </AppNavbar>
  );
};

const mapStateToProps = (s: State) => ({
  logoutFunction: s.auth.authenticated ? s.auth.logoutFunction : null
});

export default connect(mapStateToProps)(TopNavbar);
