import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import * as React from 'react';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import messages from './translations';

interface Props {
  onUpdate(): void;
  onHandhelds(): void;
  onHubs(): void;
}

export function CustomerActions({onUpdate, onHandhelds, onHubs}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const i18n = useIntl();

  function handleOpenMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleUpdate() {
    onUpdate();
    handleCloseMenu();
  }

  return (
      <>
        <IconButton
            aria-label="Actions"
            aria-haspopup="true"
            onClick={handleOpenMenu}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleUpdate}>
            {i18n.formatMessage(messages['customers.update'])}
          </MenuItem>
          <MenuItem onClick={onHubs}>
            {i18n.formatMessage(messages['customers.showHubs'])}
          </MenuItem>
          <MenuItem onClick={onHandhelds}>
            {i18n.formatMessage(messages['customers.showHandhelds'])}
          </MenuItem>
        </Menu>
      </>
  );
}
