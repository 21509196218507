export default {
  auth: {
    accessTokenValidity: Number(process.env.REACT_APP_ACCESS_TOKEN_MIN_VALIDITY_SECS) || 30,
    clientId: process.env.REACT_APP_OAUTH2_CLIENT_ID,
    realm: process.env.REACT_APP_OAUTH2_REALM,
    url: process.env.REACT_APP_OAUTH2_API_URL
  },
  backend: {
    url: process.env.REACT_APP_API_URL
  },
  customerProfile: {
    url: process.env.REACT_APP_CUSTOMER_PROFILE_API_URL
  },
  updateServer: {
    url: process.env.REACT_APP_UPDATE_SERVER_API_URL
  },
  workManagement: {
    url: process.env.REACT_APP_WORK_MANAGEMENT_API_URL
  }
};
