import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import {filter, nth, pathOr, pickBy, propOr} from 'ramda';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {AnyAction} from 'redux';
import FormPage from '../components/formPage/FormPage';
import {getCustomerFeatures} from '../customer-features/actions';
import {CustomerSetting} from '../customer-features/state';
import {Location, Locations} from '../locations/state';
import {AxiosAction, AxiosDispatch} from '../middleware/axios';
import {createSnackbar} from '../notifications/actions';
import {Setting} from '../preferences/actions';
import {State} from '../state';
import {getHandhelds} from './actions';
import {HandheldUpdateForm, SubmitProps} from './components/form/HandheldUpdateForm';
import {getHandheldRelease} from './releases/actions';
import {getRingReleases} from './ring-releases/actions';
import {Handheld, HandheldReleases, HandheldUpdates, RingRelease} from './state';
import messages from './translations';
import {getHandheldUpdate, isPutHandheldUpdateSuccessAction, putHandheldUpdate} from './updates/actions';

interface OwnProps extends RouteComponentProps<{customerId: string, handheldId: string}> {
  customerId: string;
  handheldId: string;
}

export interface Props extends OwnProps {
  dispatch: AxiosDispatch;
  handheld: Handheld;
  ringName?: string;
  ringsByVersion: Record<string, string[]>;
  handheldUpdates: HandheldUpdates;
  handheldReleases: HandheldReleases;
  locations: Locations;
  versionsByRing: Record<string, RingRelease>;
}

export const UpdateHandheld = (props: Props) => {
  const {
    dispatch,
    match,
    handheld,
    handheldReleases,
    ringName,
    versionsByRing,
    locations,
    ringsByVersion,
    handheldUpdates
  } = props;
  const customerId = match.params.customerId;
  const serialNumber = match.params.handheldId;

  const {formatMessage} = useIntl();
  const unknown = formatMessage(messages['handheld.unknown']);
  const none = formatMessage(messages['handheld.none']);

  const [location, setLocation] = useState(unknown);
  const [customerRing, setCustomerRing] = useState(unknown);
  const [desiredVersionName, setDesiredVersion] = useState(none);
  const [expectedVersionName, setExpectedVersion] = useState(unknown);
  const [expectedVersionCode, setExpectedVersionCode] = useState(0);
  const [availableVersions, setAvailableVersions] = useState({} as Record<string, RingRelease>);

  useEffect(() => {
    dispatch(getHandhelds(customerId));
    dispatch(getCustomerFeatures(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    dispatch(getHandheldRelease(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    dispatch(getRingReleases());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHandheldUpdate(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (handheld) {
      const handheldLocation = propOr(unknown, handheld.locationCode, locations.byCode) as Location;
      setLocation(handheldLocation.name || unknown);
    }
  }, [handheld, locations.byCode, unknown]);

  useEffect(() => {
    setAvailableVersions(filter(version => version.versionCode > expectedVersionCode, versionsByRing));
  }, [versionsByRing, expectedVersionCode]);

  useEffect(() => {
    setExpectedVersion(pathOr(
        unknown,
        ['byHandheldSerialNumber', serialNumber],
        handheldReleases
    ));
  }, [handheldReleases, serialNumber, unknown]);

  useEffect(() => {
    setDesiredVersion(pathOr(
        none,
        ['bySerialNumber', serialNumber, 'versionName'],
        handheldUpdates
    ));
  }, [handheldUpdates, serialNumber, none]);

  useEffect(() => {
    setExpectedVersionCode(pathOr(0, ['byVersionName', expectedVersionName, 'versionCode'], handheldReleases));
  }, [handheldReleases, expectedVersionName]);

  useEffect(() => {
    setCustomerRing(ringName || unknown);
  }, [ringName, unknown]);

  const onSubmit = ({versionCode, versionName}: SubmitProps): Promise<AnyAction> =>
    dispatch(putHandheldUpdate(
      serialNumber,
      {
        customerId,
        versionCode,
        versionName
      },
      props.history
      )).then((action: AxiosAction) => {
        if (isPutHandheldUpdateSuccessAction(action)) {
          dispatch(createSnackbar({
            message: formatMessage(messages['handheld.update.success']),
            options: {
              variant: 'success'
            }
          }));
        } else {
          dispatch(createSnackbar({
            message: formatMessage(messages['handheld.update.error']),
            options: {
              variant: 'error'
            }
          }));
        }
        return action;
      });

  return (
    <FormPage pageTitle={formatMessage(messages['handheld.update'])} pageIcon={<PhoneAndroid/>}>
      <HandheldUpdateForm
          availableVersions={availableVersions}
          customerId={customerId}
          handheld={handheld}
          desiredVersion={desiredVersionName}
          expectedVersion={expectedVersionName}
          expectedVersionCode={expectedVersionCode}
          ringName={customerRing}
          location={location}
          onSubmit={onSubmit}
          versionsByRing={versionsByRing}
          ringsByVersion={ringsByVersion}
      />
    </FormPage>
  );
};

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({dispatch});

const getRingNameFromFeatureSettings = (state: State): string | undefined => {
  const settings = pickBy(
    (value: CustomerSetting) => value.settingCode === 'CORE.HANDHELD_RING',
    state.customerFeatures.settingsByCode
  );

  const setting = nth(0, Object.values(settings as Setting));
  return setting && setting.value;
};

const mapStateToProps = (state: State, props: OwnProps) => {
  const params = props.match.params;
  return {
    handheld: state.handhelds.bySerialNumber[params.handheldId],
    handheldReleases: state.handheldReleases,
    handheldUpdates: state.handheldUpdates,
    locations: state.locations,
    ringName: getRingNameFromFeatureSettings(state),
    ringsByVersion: state.ringReleases.byVersionName,
    versionsByRing: state.ringReleases.byRing
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateHandheld));
