import makeStyles from '@material-ui/core/styles/makeStyles';
import {indexBy, pathOr, prop} from 'ramda';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {FormPanel} from '../../components/form-panel/FormPanel';
import {getCustomerFeatures} from '../../customer-features/actions';
import {CustomerSetting} from '../../customer-features/state';
import FeatureComponent, {SettingValueLookup} from '../../features/component/FeatureComponent';
import {Features} from '../../features/state';
import {AxiosDispatch} from '../../middleware/axios';
import {State} from '../../state';
import messages from '../translations';

interface OwnProps {
  customerId: string;
}

interface Props extends OwnProps {
  customerFeatures: string[];
  customerSettings: string[];
  customerSettingsByCode: Record<string, CustomerSetting>;
  features: Features;
  dispatch: AxiosDispatch;
}

const useStyles = makeStyles({
  wrapper: {
    width: '100%'
  }
});

export const CustomerFeatures = (props: Props) => {
  const {customerId, customerFeatures, customerSettingsByCode, customerSettings, features, dispatch} = props;

  const indexedCustomerSettings = indexBy(
      prop('settingCode'),
      customerSettings.map(code => customerSettingsByCode[code])
  );

  useEffect(() => {
    dispatch(getCustomerFeatures(customerId));
  }, [dispatch, customerId]);

  const customerValue: SettingValueLookup = (settingCode) => {
    const customerSettingValue = pathOr(null, [settingCode, 'value'], indexedCustomerSettings);

    if (customerSettingValue === pathOr(null, [settingCode, 'defaultValue'], features.settingsByCode)) {
      return null;
    }

    return customerSettingValue;
  };

  const classes = useStyles();

  const {formatMessage} = useIntl();

  return (
      <FormPanel
          heading={formatMessage(messages['customers.features'])}
          secondaryHeading={formatMessage(messages['customers.featuresEnabled'], {n: customerFeatures.length})}
          name="features"
          initialExpanded={false}
      >
        <div className={classes.wrapper}>
          {features.allCodes.map(featureCode => {
            return (
                <FeatureComponent
                    key={featureCode}
                    enabled={customerFeatures.includes(featureCode)}
                    featureCode={featureCode}
                    settingColumns={[{
                      title: formatMessage(messages['customers.customer']),
                      value: customerValue
                    }]}
                />
            );
          })}
        </div>
      </FormPanel>
  );
};

const mapStateToProps = (state: State, props: OwnProps) => ({
  customerFeatures: state.customerFeatures.features[props.customerId] || [],
  customerSettings: state.customerFeatures.settingsByCustomerId[props.customerId] || [],
  customerSettingsByCode: state.customerFeatures.settingsByCode,
  features: state.features
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeatures);
