import {Typography} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {useTheme} from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import DeviceHub from '@material-ui/icons/DeviceHub';
import Drafts from '@material-ui/icons/Drafts';
import Mood from '@material-ui/icons/Mood';
import React, {ReactElement} from 'react';
import {useIntl} from 'react-intl';
import {defineMessages, FormattedMessage} from 'react-intl.macro';
import {Link} from 'react-router-dom';
import customerMessages from '../../customers/translations';
import hubMessages from '../../hubs/translations';
import styles from './styles';

export interface Props {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

const messages = defineMessages({
  'app.customers': {
    defaultMessage: 'Customers',
    id: 'app.customers'
  },
  'app.features': {
    defaultMessage: 'Features',
    id: 'app.features'
  },
  'app.hubs': {id: 'app.hubs', defaultMessage: 'Hubs'}
});

interface AddLinkProps {
  children: ReactElement;
  link: string;
  text: string;
}

export const SideNavbar = (props: Props) => {
  const {handleDrawerToggle, mobileOpen} = props;

  const theme = useTheme();
  const classes = styles();

  const {formatMessage} = useIntl();

  const ActionLink = ({link, text, children}: AddLinkProps) => (
      <List component="div" disablePadding={true}>
        <ListItem component={Link} to={link} className={classes.nested}>
          <ListItemIcon>
            {children}
          </ListItemIcon>
          <ListItemText secondary={text} />
        </ListItem>
      </List>
  );

  const content = (clickHandler?: typeof handleDrawerToggle) => (
    <div className={classes.sideBarList}>
      <div className={classes.appBar}/>
      <Hidden xsDown={true}>
        <Typography variant="h6" align="center">
          <FormattedMessage id="app.name" defaultMessage="Office"/>
        </Typography>
      </Hidden>
      <List onClick={clickHandler}>
        <ListItem component={Link} to="/customers">
          <ListItemIcon><Mood /></ListItemIcon>
          <ListItemText
              secondary={formatMessage(messages['app.customers'])}
          />
        </ListItem>
        <ActionLink link="/customers/new" text={formatMessage(customerMessages['customers.addNewCustomer'])}>
          <Add />
        </ActionLink>
        <ListItem component={Link} to="/hubs">
          <ListItemIcon><DeviceHub /></ListItemIcon>
          <ListItemText
              secondary={formatMessage(messages['app.hubs'])}
          />
        </ListItem>
        <ActionLink link="/hubs/new" text={formatMessage(hubMessages['hubs.create'])}>
          <Add />
        </ActionLink>
        <ListItem component={Link} to="/features">
          <ListItemIcon><Drafts /></ListItemIcon>
          <ListItemText
              secondary={formatMessage(messages['app.features'])}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden smUp={true} implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {content(handleDrawerToggle)}
        </Drawer>
      </Hidden>
      <Hidden xsDown={true} implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open={true}
        >
          {content()}
        </Drawer>
      </Hidden>
    </nav>
  );
};
