import {defineMessages} from 'react-intl.macro';
import {CustomerType, Module} from './state';

export default defineMessages({
  [Module.AUTOMATED_MONITORING]: {
    defaultMessage: 'AM',
    id: 'customers.am'
  },
  [Module.WORK_MANAGEMENT]: {
    defaultMessage: 'WM',
    id: 'customers.wm'
  },
  [Module.AUTOMATED_MONITORING + '.name']: {
    defaultMessage: 'Automated Monitoring',
    id: 'customers.am.name'
  },
  [Module.WORK_MANAGEMENT + '.name']: {
    defaultMessage: 'Work Management',
    id: 'customers.wm.name'
  },
  [CustomerType.LIVE]: {id: 'customers.type.live', defaultMessage: 'Live'},
  [CustomerType.DORMANT]: {id: 'customers.type.dormant', defaultMessage: 'Dormant'},
  [CustomerType.INTERNAL]: {id: 'customers.type.internal', defaultMessage: 'Internal'},
  [CustomerType.TRIAL]: {id: 'customers.type.trial', defaultMessage: 'Trial'},
  'customers.actions': {id: 'customers.actions', defaultMessage: 'Actions'},
  'customers.addNewCustomer': {id: 'customers.addNewCustomer', defaultMessage: 'Add New Customer'},
  'customers.addOptionalTemplate': {id: 'customers.addOptionalTemplate', defaultMessage: 'Add Optional Template'},
  'customers.chooseTemplate': {id: 'customers.chooseTemplate', defaultMessage: 'Choose a Template'},
  'customers.create.error': {id: 'customers.create.error', defaultMessage: 'Customer Creation Failed'},
  'customers.create.success': {id: 'customers.create.success', defaultMessage: 'Customer Created Successfully'},
  'customers.customer': {id: 'customers.customer', defaultMessage: 'Customer'},
  'customers.customerId': {id: 'customers.customerId', defaultMessage: 'Customer ID'},
  'customers.editCustomer': {id: 'customers.editCustomer', defaultMessage: 'Edit Customer'},
  'customers.features': {id: 'customers.features', defaultMessage: 'Features'},
  'customers.featuresEnabled': {id: 'customers.featuresEnabled', defaultMessage: '{n, plural, =0 {no features} one {# feature} other {# features}} enabled'},
  'customers.firstUserEmail': {id: 'customers.firstUserEmail', defaultMessage: "First user's email address"},
  'customers.firstUserEmail.required': {id: 'customers.firstUserEmail.required', defaultMessage: "First user's email address is required"},
  'customers.firstUserEmail.validEmail': {id: 'customers.firstUserEmail.validEmail', defaultMessage: "First user's email address must be a valid email address"},
  'customers.firstUserName': {id: 'customers.firstUserName', defaultMessage: "First user's name"},
  'customers.firstUserName.nonBlank': {id: 'customers.firstUserName.nonBlank', defaultMessage: "First user's name must not be blank"},
  'customers.firstUserName.required': {id: 'customers.firstUserName.required', defaultMessage: "First user's name is required"},
  'customers.isTemplate': {id: 'customers.isTemplate', defaultMessage: 'Is Template'},
  'customers.modules': {id: 'customers.modules', defaultMessage: 'Modules'},
  'customers.modules.required': {id: 'customers.modules.required', defaultMessage: 'At least one module must be selected'},
  'customers.name': {id: 'customers.name', defaultMessage: 'Name'},
  'customers.name.maxLength': {id: 'customers.name.maxLength', defaultMessage: 'Name can be at most {maxLength} characters long'},
  'customers.name.nonBlank': {id: 'customers.name.nonBlank', defaultMessage: 'Name must not be blank'},
  'customers.name.required': {id: 'customers.name.required', defaultMessage: 'Name is required'},
  'customers.showHandhelds': {id: 'customers.showHandhelds', defaultMessage: 'Show Handhelds'},
  'customers.showHubs': {id: 'customers.showHubs', defaultMessage: 'Show Hubs'},
  'customers.templating': {id: 'customers.templating', defaultMessage: 'Templating'},
  'customers.title': {id: 'customers.title', defaultMessage: 'Customers'},
  'customers.type': {id: 'customers.type', defaultMessage: 'Type'},
  'customers.update': {id: 'customers.update', defaultMessage: 'Update'},
  'customers.update.error': {id: 'customers.update.error', defaultMessage: 'Customer Update Failed'},
  'customers.update.success': {id: 'customers.update.success', defaultMessage: 'Customer Updated Successfully'}
});
