import {compose, indexBy, map, mapObjIndexed, mergeLeft, omit, prop, union} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {isGetHandheldsSuccessAction} from '../handhelds/actions';
import {Location, Locations} from './state';

const initialState: Locations = {
  addressesByCode: {},
  allCodes: [],
  byCode: {}
};

export const locations = combineReducers({
  addressesByCode,
  allCodes,
  byCode
});

function allCodes(state = initialState.allCodes, action: AnyAction): Locations['allCodes'] {
  if (isGetHandheldsSuccessAction(action)) {
    return union(state, action.payload.data.map(handheld => handheld.location.code));
  }

  return state;
}

const extractLocation = prop<'location', Location>('location');
const stripAddress = omit(['address']);
const indexByCode = indexBy<Location>(prop('code'));
const parseLocations = compose(indexByCode, map(compose(stripAddress, extractLocation)));

function byCode(state = initialState.byCode, action: AnyAction): Locations['byCode'] {
  if (isGetHandheldsSuccessAction(action)) {
    const parsedLocations = parseLocations(action.payload.data);
    return mergeLeft(state, parsedLocations);
  }

  return state;
}

const extractAddress = prop('address');
const parseAddresses = compose(mapObjIndexed(extractAddress), indexByCode, map(extractLocation));

function addressesByCode(state = initialState.addressesByCode, action: AnyAction): Locations['addressesByCode'] {
  if (isGetHandheldsSuccessAction(action)) {
    return mergeLeft(state, parseAddresses(action.payload.data));
  }

  return state;
}
