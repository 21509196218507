import {Card, CardContent, Theme} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {filter} from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {State} from '../../state';
import {Feature, Setting, SettingWithFeatureCode} from '../state';

export type SettingValueLookup = (settingCode: string) => string | null;

export interface SettingColumn {
  value: SettingValueLookup;
  title: string;
}

interface OwnProps {
  enabled: boolean;
  featureCode: string;
  settingColumns: SettingColumn[];
}

export interface Props extends OwnProps {
  feature: Feature;
  settings: Record<string, Setting>;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  }
}));

export const FeatureComponent = (props: Props) => {
  const {feature, settings} = props;

  const classes = useStyles();

  const settingColumns = props.settingColumns.concat([{
    title: 'Default Value',
    value: (settingCode) => settings[settingCode].defaultValue
  }]);

  return (
      <Card className={classes.card} raised={true}>
        <CardHeader titleTypographyProps={{variant: 'h6'}} title={feature.name}/>
        {feature.description ? <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">{feature.description}</Typography>
        </CardContent> : null}
        <CardContent>
          <FormControlLabel
              control={
                <Checkbox
                    checked={props.enabled}
                    disabled={true}
                    value={props.enabled}
                    color="primary"
                />
              }
              label="Enabled"
          />
        </CardContent>
        {/* Don't show settings for disabled features */}
        {props.enabled ?
            <CardContent>
              {feature.settings && feature.settings.length > 0 ?
                  <>
                    <Typography variant="h6" component="h3">Settings</Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          {settingColumns.map(column => <TableCell key={column.title}>{column.title}</TableCell>)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feature.settings.map(settingCode => (
                            <TableRow key={settingCode}>
                              <TableCell>
                                <Tooltip title={settings[settingCode].description || ''} placement="right">
                                  <span>{settings[settingCode].name}</span>
                                </Tooltip>
                              </TableCell>
                              {settingColumns.map(column =>
                                  <TableCell key={[settingCode, column.title].join('.')}>
                                    {column.value(settingCode)}
                                  </TableCell>
                              )}
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                  : <Typography variant="body2" color="textSecondary" component="p">
                    {feature.name} has no settings.
                  </Typography>}
            </CardContent> : null}
      </Card>
  );
};

const filterByFeature = (featureCode: string, settings: Record<string, SettingWithFeatureCode>) =>
    filter(s => s.featureCode === featureCode, settings);

const mapStateToProps = (state: State, props: OwnProps) => ({
  feature: state.features.byCode[props.featureCode],
  settings: filterByFeature(props.featureCode, state.features.settingsByCode)
});

export default connect(mapStateToProps)(FeatureComponent);
